export const config = {
  apiUrl: 'http://localhost:3038',
  corporateSiteUrl: 'http://localhost:3020',
  customerPortalUrl: 'http://localhost:3019',
  hubspotTrackingCode: '8471493',
  inactivityTimeout: 60,
  onfido: {
    sdkReferrer: 'http://localhost:3039/**',
  },
  sentry: {
    dsn: '',
    enabled: false,
  },
  recaptchaSiteKey: '6LcPzqIZAAAAAIlWITFL-v5-7PTl70zN7GBmBgBV',
  webAuth: {
    domain: 'localhost',
    origin: 'http://localhost:3039'
  },
  isScriveEnabled: true,
  scriveProviders: [
    'seBankID',
    'noBankID',
    'nlIDIN',
    'fiTupas'
  ],
  inchargeProductAccountId: null,
  is2dEidEnabled: false,
  intercomApiBase: 'https://api-iam.eu.intercom.io',
  localazy: {
    onboardingUiUrl: 'https://delivery.localazy.com/_a8028343539560048533d7d85a49/_e0/8aa177b62882c45a14c0714ed9b0ade6cab16f0d/{{lng}}/onboarding-ui.json',
    onfidoUrl: 'https://delivery.localazy.com/_a8028343539560048533d7d85a49/_e0/607d229647a2240f3c99f5f6b1ebcde97a496c3f/{{lng}}/onfido.json',
  },
  seon: {
    host: 'seondfresolver.com',
    enabled: false,
  }
};
