export const ONFIDO_STEPS = [
  {
    type: 'document',
    options: {
      forceCrossDevice: true,
    }
  },
  {
    type: 'face',
    options: {
      requestedVariant: 'video',
    }
  },
];
